import React, { useEffect, useRef } from 'react';
import s from './BgLine5.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine5 = ({ pageThemeLight=false }) => {

  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
      gsap.from(lineRef.current, {
        drawSVG: '0%',
        duration: 4,
        ease: 'none',
        scrollTrigger: {
          trigger: lineRef.current,
          start:'top center',
          end: 'bottom bottom',
          scrub: true,
        },
      });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef} >
      <svg className={s('path__draw__1', {pageThemeLight})}
        preserveAspectRatio='none'
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path ref={lineRef} xmlns="http://www.w3.org/2000/svg" opacity="0.5" d="M1 1H126.959C237.416 1 326.959 90.543 326.959 201V314.839C326.959 425.296 417.543 514.839 528 514.839V514.839H857.5C882.5 514.839 936 516.771 936 585.664C936 654.558 936 1032.12 936 1222.46C936 1276.46 870 1406.46 758 1406.46C601 1406.46 17.5 1406.46 0 1406.46H287H532C570.5 1410.46 631.1 1434.56 663.5 1476.96C704 1529.96 711 1560.96 711 1617.96C711 1663.56 711 2036.17 711 2116.5" stroke="#1B52AD" stroke-width="2"/>
      </svg>
    </div>
  );
};