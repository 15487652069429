import React, { useEffect, useRef } from 'react';
import s from './BgLine4.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine4 = ({ pageThemeLight=false }) => {

  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
      gsap.from(lineRef.current, {
        drawSVG: '0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start:'top center',
          end: "bottom center"
        },
      });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef} >
      <svg className={s('path__draw__1', {pageThemeLight})}
        preserveAspectRatio='none'
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path ref={lineRef} xmlns="http://www.w3.org/2000/svg" opacity="0.5" d="M0 1H285C367.843 1 435 68.1573 435 151V363.04C435 445.882 502.157 513.04 585 513.04H835H1134C1215.09 513.04 1265 571.5 1265 662.5V1129.5C1265 1203 1318.8 1279.5 1418 1279.5C1517.2 1279.5 1718 1279.5 1806 1279.5" stroke="#1B52AD" stroke-width="2"/>
      </svg>
    </div>
  );
};